<template>
  <div>
    <b-row class="match-height">
      <b-col md="6">
        <b-card>
          <b-row>
            <b-col md="5">
              <b-form-group label="Peride Penggunaan Alat">
                <v-select v-model="input.bulan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="bulan"
                  @input="get_status_pengajuan(input.bulan)" :options="bulan" />
              </b-form-group>
            </b-col>
            <b-col md="5" v-show="input.bulan">
              <b-form-group label=" Status Peminjaman">
                <v-select v-model="input.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="status"
                  @input="load_Data(input.bulan)" :options="status_pengajuan" />
              </b-form-group>
            </b-col>
            <b-col md="2" v-show="input.status">
              <b-form-group label="" class="mt-2">
                <b-button variant="primary" @click="load_Data(input.bulan)"><feather-icon icon="RefreshCcwIcon"
                    size='15' />
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="bg-light-warning">
          <h4><b>Pendapatan</b> </h4>
          <b-row>
            <b-col md="4">
              <h4><b><small>Uang Sewa</small></b> </h4>
              <h4 class="text-info">
                <b> <sup>Rp.</sup> {{ Rp(items.pemasukan.uang_sewa) }}</b><br>

              </h4>
            </b-col>
            <b-col md="4">
              <h4><b><small>Uang Denda</small></b> </h4>
              <h4 class="text-warning">
                <b> <sup>Rp.</sup> {{ Rp(items.pemasukan.uang_denda) }}</b><br>

              </h4>
            </b-col>
            <b-col md="4">
              <h4><b><small>Total Pemasukan</small></b> </h4>
              <h4 class="text-primary">
                <b> <sup>Rp.</sup> {{ Rp(items.pemasukan.pemasukan) }}</b><br>
              </h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>



    <b-row>
      <b-col md="12">
        <b-card>
          <DaftraLaporan :items=items.result></DaftraLaporan>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import axios from '@/config/Axios';
import vSelect from 'vue-select'
import { BFormInput, BRow, BCol, BFormGroup, BFormSelect, BCard, BCardBody, BButton } from 'bootstrap-vue'
import DaftraLaporan from './DaftraLaporan.vue'
export default {
  components: {
    BFormInput, BRow, BCol, BFormGroup, BFormSelect, BCard, BCardBody, BButton,
    DaftraLaporan, vSelect
  },
  mixins: [Base],
  data() {
    return {
      bulan: [],
      input: {
        tgl_mulai: '',
        status: ''
      },
      status_pengajuan: [],
      items: {
        result: [],
        pemasukan: {
          uang_sewa: 0,
          uang_denda: 0,
          pemasukan: 0
        }
      }
    }
  },
  mounted() {
    this.get_bulan();
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    async get_bulan() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/laporan/bulan',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.bulan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async get_status_pengajuan(input) {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/laporan/status_pengajuan',
        data: {
          tgl_mulai: input.tgl_mulai
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.status_pengajuan = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },

    async load_Data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/laporan/load_Data',
        data: {
          tgl_mulai: self.input.bulan.tgl_mulai,
          status: self.input.status.status
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.items = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style></style>