<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50"></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="6" sm="8" class="my-1">

    </b-col>
    <b-col md="4" class="my-1">
      <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="5">
              <small class="text-primary">Nama Balai/BPJ</small>
              <h3 class="text-primary"><strong>{{ data.item.nama_umkm }}</strong><br><small>Ho Hp/Wa {{
                data.item.no_hp }}</small></h3>

              <h6 class="text-disabled">
                <srong style="text-align: justify;">Alat Yang dipinjam {{ data.item.nama_alat }}
                  - {{
                    data.item.kode_item
                  }}</srong>
              </h6>
            </b-col>
            <b-col md="4">
              <b-row>
                <b-col md="6">
                  <small class="text-warning"> </small>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <small class="text-warning">Tgl Pinjamn {{ data.item.tgl_mulai }} sampai dengan {{
                    data.item.tgl_selesai }}</small>
                  <br>
                  <strong>Keterangan Pengembalian </strong><br>
                  <small style="text-align: justify;">Diekembalikan pdata tanggal {{ data.item.tgl_pengembalian }}, {{
                    data.item.keteranganpengembalian }}</small>
                </b-col>
              </b-row>


            </b-col>
            <b-col md="3">

              <b-row>
                <b-col md="6">
                  <h6><b><small>Uang Sewa</small></b> </h6>
                  <h6 class="text-info">
                    <b> <sup>Rp.</sup> {{ Rp(data.item.uang_sewa) }}</b><br>

                  </h6>
                </b-col>
                <b-col md="6">
                  <h6><b><small>Uang Denda</small></b> </h6>
                  <h6 class="text-warning">
                    <b> <sup>Rp.</sup> {{ Rp(data.item.uang_denda) }}</b><br>

                  </h6>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],


    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  props: {
    items: {}
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    Rp(value) {
      let val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: index`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}
</style>>